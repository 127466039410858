import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M4490 7470 l0 -1440 205 0 205 0 2 1039 3 1038 210 -158 c116 -88
224 -169 242 -182 66 -46 954 -717 963 -727 17 -19 71 -50 87 -50 10 0 72 41
138 91 66 50 216 164 334 253 118 88 230 173 250 188 20 15 47 36 61 45 61 43
267 200 290 220 14 13 28 23 32 23 3 0 13 6 20 13 14 14 368 282 478 362 112
81 281 209 296 223 11 11 14 60 14 252 0 164 -3 241 -11 246 -6 3 -14 3 -18
-2 -3 -5 -78 -63 -166 -129 -193 -144 -173 -128 -215 -163 -31 -25 -103 -78
-203 -150 -17 -13 -44 -34 -59 -48 -15 -13 -32 -24 -38 -24 -5 0 -10 -3 -10
-7 0 -5 -17 -19 -38 -33 -20 -14 -59 -42 -87 -63 -27 -21 -102 -78 -165 -125
-63 -48 -117 -89 -120 -92 -3 -3 -34 -27 -70 -52 -36 -26 -69 -51 -75 -56 -5
-4 -62 -47 -125 -94 -63 -48 -205 -154 -315 -237 -119 -89 -203 -147 -210
-142 -16 13 -285 216 -590 445 -154 116 -287 216 -295 223 -25 20 -319 240
-429 321 -57 43 -109 82 -115 87 -6 6 -27 22 -46 35 -19 13 -46 35 -60 47 -14
13 -28 23 -32 23 -3 0 -13 6 -20 13 -7 7 -56 44 -108 83 -52 40 -115 88 -140
108 -25 20 -51 36 -57 36 -10 0 -13 -295 -13 -1440z"/>
<path d="M8200 7875 c-47 -36 -89 -68 -95 -73 -75 -58 -144 -110 -162 -122
-12 -8 -111 -82 -220 -165 -108 -82 -209 -157 -223 -167 -14 -9 -38 -28 -54
-42 -16 -14 -32 -26 -35 -26 -3 0 -21 -13 -38 -28 -18 -15 -50 -40 -70 -55
-130 -97 -301 -226 -453 -340 -200 -149 -210 -156 -338 -254 -53 -40 -99 -73
-104 -73 -8 0 -70 46 -347 255 -157 118 -216 162 -232 171 -11 6 -32 22 -47
35 -26 23 -61 50 -192 147 -30 23 -109 82 -175 131 -66 50 -126 91 -132 91
-10 0 -13 -55 -13 -240 0 -222 1 -241 19 -257 22 -22 162 -127 561 -425 52
-39 128 -96 169 -127 263 -198 377 -283 383 -287 5 -3 225 155 283 204 17 13
126 96 149 112 34 24 180 134 186 140 3 3 21 17 40 30 19 13 37 27 40 30 5 6
467 355 504 380 11 8 82 61 157 118 75 56 141 102 147 102 9 0 12 -140 12
-555 l0 -555 200 0 200 0 0 955 c0 903 -1 955 -17 955 -10 0 -56 -30 -103 -65z"/>
<path d="M3925 5508 c-37 -95 -101 -256 -141 -358 -40 -102 -80 -202 -88 -223
l-17 -38 62 3 61 3 45 113 45 112 140 0 139 0 21 -47 c11 -25 32 -76 47 -112
l26 -66 58 -3 c62 -3 65 0 42 50 -27 61 -285 719 -285 729 0 5 -20 9 -43 9
l-44 0 -68 -172z m160 -152 c25 -70 45 -129 45 -131 0 -3 -43 -5 -95 -5 -52 0
-95 4 -95 9 0 8 28 90 85 250 8 21 12 13 60 -123z"/>
<path d="M6025 5427 c-146 -370 -205 -521 -205 -530 0 -4 25 -7 57 -5 l56 3
29 70 c16 39 34 84 40 100 20 55 21 55 167 55 l137 0 27 -68 c15 -38 36 -90
47 -115 l21 -47 54 0 c30 0 55 4 55 9 0 5 -66 175 -146 377 -80 203 -148 377
-151 387 -4 13 -16 17 -47 17 l-41 0 -100 -253z m194 -74 c22 -67 41 -124 41
-127 0 -3 -43 -6 -95 -6 -52 0 -95 2 -95 4 0 4 74 218 86 249 10 27 17 14 63
-120z"/>
<path d="M7138 5557 c-26 -67 -92 -237 -148 -377 -56 -140 -104 -263 -107
-272 -5 -16 2 -18 53 -18 l59 0 45 113 45 112 141 3 142 3 41 -103 c23 -57 45
-109 50 -116 5 -9 26 -12 64 -10 l55 3 -52 130 c-29 72 -100 248 -157 393
l-103 262 -41 0 -40 0 -47 -123z m141 -202 c23 -65 41 -123 41 -127 0 -4 -43
-8 -96 -8 -73 0 -95 3 -92 13 3 6 25 67 49 134 23 68 46 120 50 115 4 -4 26
-61 48 -127z"/>
<path d="M8197 5669 c-90 -14 -169 -66 -205 -134 -21 -40 -20 -150 1 -192 33
-63 120 -105 242 -117 148 -15 185 -37 185 -110 0 -38 -5 -48 -35 -74 -80 -70
-211 -69 -274 4 -17 19 -31 41 -31 49 0 11 -13 15 -55 15 -50 0 -55 -2 -55
-22 1 -34 34 -100 66 -130 55 -51 120 -73 214 -72 71 0 94 5 142 28 125 59
177 187 122 297 -33 66 -107 101 -257 123 -112 17 -157 38 -169 81 -17 66 19
118 99 141 86 25 178 -3 205 -61 10 -22 17 -25 65 -25 59 0 63 7 39 63 -40 95
-172 156 -299 136z"/>
<path d="M2967 5664 c-4 -4 -7 -180 -7 -391 l0 -383 55 0 55 0 0 265 c0 146 4
265 9 265 4 0 52 -65 105 -145 88 -132 99 -145 126 -145 26 0 38 13 126 145
54 80 101 145 106 145 4 0 8 -119 8 -265 l0 -265 55 0 55 0 -2 388 -3 387 -44
0 -44 0 -126 -187 c-69 -103 -128 -187 -131 -187 -3 0 -62 84 -131 187 -125
186 -125 187 -165 190 -22 2 -43 0 -47 -4z"/>
<path d="M4400 5281 l0 -391 55 0 55 0 0 265 c0 146 4 265 9 265 4 0 52 -65
105 -145 131 -195 122 -196 258 8 l103 152 3 -272 2 -273 55 0 55 0 -2 388 -3
387 -41 3 -41 3 -69 -101 c-158 -231 -186 -270 -194 -270 -5 0 -41 48 -81 108
-184 272 -171 257 -223 260 l-46 3 0 -390z"/>
<path d="M5197 5663 c-4 -3 -7 -179 -7 -391 l0 -384 198 4 c192 3 198 4 250
31 74 39 124 87 160 155 86 160 54 374 -73 491 -59 54 -115 79 -207 91 -84 11
-311 13 -321 3z m388 -132 c51 -23 118 -96 136 -147 8 -21 13 -73 13 -114 0
-118 -54 -204 -154 -249 -37 -17 -66 -21 -162 -21 l-118 0 0 275 0 275 123 0
c97 0 130 -4 162 -19z"/>
<path d="M6400 5610 l0 -60 120 0 120 0 0 -335 0 -335 49 0 c32 0 51 5 54 14
3 7 5 158 4 334 l-2 321 115 3 115 3 3 58 3 57 -291 0 -290 0 0 -60z"/>
<path d="M8605 5662 c-3 -3 -5 -135 -5 -294 0 -272 1 -292 21 -338 22 -52 54
-81 129 -121 62 -32 202 -34 270 -3 63 29 105 67 129 117 20 41 21 60 21 344
l0 300 -37 1 c-78 3 -73 25 -73 -300 l0 -290 -33 -33 c-76 -76 -241 -69 -294
12 -16 24 -19 63 -23 318 l-5 290 -47 1 c-26 1 -50 -1 -53 -4z"/>
<path d="M9268 5663 c-17 -4 -18 -31 -18 -389 l0 -384 203 0 c196 0 205 1 253
25 80 40 133 141 120 232 -8 60 -41 119 -80 145 l-33 22 31 44 c26 37 31 53
31 101 -1 104 -64 182 -164 201 -52 10 -309 12 -343 3z m373 -132 c27 -33 26
-110 -2 -145 -20 -26 -22 -26 -150 -26 l-129 0 0 101 0 101 131 -4 c123 -3
132 -4 150 -27z m26 -307 c41 -30 59 -92 41 -146 -23 -73 -48 -83 -206 -83
l-137 0 -3 114 c-1 63 0 121 3 129 4 11 32 13 139 10 118 -3 138 -6 163 -24z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
